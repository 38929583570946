import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge, Progress } from 'reactstrap';

import { ChartBarBasicStyled } from './styles';

const ChartBarBasic = ({ title, data, totalCount, color }) => {
  const { t: translate } = useTranslation();

  return (
    <ChartBarBasicStyled color={color}>
      <>
        <span className="title">{title}</span>
        <div className="d-flex flex-column gap-4 w-100 h-100 align-items-center">
          {data.map((item, index) => (
            <React.Fragment key={`${index}${item.item}`}>
              <div className="d-flex flex-column gap-3 w-100">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <div className="circle" />
                    <span className="title-item">{translate(item.item)}</span>
                    <Badge color="light" pill>
                      {item.total}
                    </Badge>
                  </div>
                  <span className="tag">{((item.total / totalCount) * 100).toFixed(0)}%</span>
                </div>
                <Progress value={((item.total / totalCount) * 100).toFixed(0)} />
              </div>
            </React.Fragment>
          ))}
        </div>
      </>
    </ChartBarBasicStyled>
  );
};

ChartBarBasic.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  totalCount: PropTypes.number,
  color: PropTypes.string,
};

export default ChartBarBasic;
