import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import { getDateByWeekNumber, getNextMondayDate } from 'utils';
import { MoonLoader } from 'react-spinners';

import { PerformanceDataStyles } from './styles';
import PerformanceTable from '../Performance-Table';
import Shimmer from 'components/Shimmer';

const PerformanceData = ({ publicationDate }) => {
  const firebaseHelper = getFirebaseBackend();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const { t: translate } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [performanceData, setPerformanceData] = useState([]);
  const [hasKeywordMonitoring, setHasKeywordMonitoring] = useState(true);
  const [domain, setDomain] = useState('');

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getGuidelinePerformance`,
        {
          accountId: currentAccountId,
          guidelineId: id,
          development: process.env.REACT_APP_BUILD_TYPE !== 'production',
        },
        {
          headers: {
            Authorization: `Bearer ${await firebaseHelper.getIdToken()}`,
          },
        },
      );

      setPerformanceData(
        Object.entries(response.data).map(([key, value]) => ({
          ['week']: key,
          ['data']: Object.entries(value).map(([keyData, valueData]) => ({
            ['keyword']: keyData,
            ['data']: valueData,
          })),
        })),
      );
      setHasKeywordMonitoring(Object.entries(response.data.length > 0));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching performance data:', error);
      if (error.response && error.response.status === 404) {
        setHasKeywordMonitoring(false);
      }
      setIsLoading(false);
    }
  };

  if (publicationDate) {
    const date = new Date(publicationDate);
    const formattedDay = String(date.getDate()).padStart(2, '0');
    const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    publicationDate = `${formattedDay}/${formattedMonth}/${year}`;
  }

  const formatedLabels = performanceData?.map((week) => {
    const date = getDateByWeekNumber(week.week);
    const weekNumber = translate('Week') + ' ' + week.week.split('-')[1];
    return `${weekNumber}\n${date}`;
  });

  function countKeywords() {
    const uniqueKeywords = new Set();
    let totalWeeks = 0;

    performanceData.forEach((week) => {
      week.data.forEach((keyword) => {
        uniqueKeywords.add(keyword);
      });
      totalWeeks++;
    });

    const keywordsPerWeek = uniqueKeywords.size / totalWeeks;

    return keywordsPerWeek;
  }

  const datasetsData = [
    {
      label: 'Sem rank',
      backgroundColor: '#EAEAEA',
      borderWidth: 0,
      data: performanceData
        ?.slice(-8)
        ?.reverse()
        .map((week) =>
          week.data.reduce((sum, keyword) => sum + (keyword.data.rank['no-rank'] || 0), 0),
        ),
    },
    {
      label: '20+',
      backgroundColor: '#A0AEC0',
      borderWidth: 0,
      data: performanceData
        ?.slice(-8)
        ?.reverse()
        .map((week) =>
          week.data.reduce((sum, keyword) => sum + (keyword.data.rank['20+'] || 0), 0),
        ),
    },
    {
      label: '11 a 20',
      backgroundColor: '#BEE3F8',
      borderWidth: 0,
      data: performanceData
        ?.slice(-8)
        ?.reverse()
        .map((week) =>
          week.data.reduce((sum, keyword) => sum + (keyword.data.rank['11-20'] || 0), 0),
        ),
    },
    {
      label: '7 a 10',
      backgroundColor: '#63B3ED',
      borderWidth: 0,
      data: performanceData
        ?.slice(-8)
        ?.reverse()
        .map((week) =>
          week.data.reduce((sum, keyword) => sum + (keyword.data.rank['7-10'] || 0), 0),
        ),
    },
    {
      label: '4 a 6',
      backgroundColor: '#3182CE',
      borderWidth: 0,
      data: performanceData
        ?.slice(-8)
        ?.reverse()
        .map((week) =>
          week.data.reduce((sum, keyword) => sum + (keyword.data.rank['4-6'] || 0), 0),
        ),
    },
    {
      label: '1 a 3',
      backgroundColor: '#F6B950',
      borderWidth: 0,
      data: performanceData
        ?.slice(-8)
        ?.reverse()
        .map((week) =>
          week.data.reduce((sum, keyword) => sum + (keyword.data.rank['1-3'] || 0), 0),
        ),
    },
  ];

  const data = {
    labels: formatedLabels.slice(-8)?.reverse(),
    datasets: datasetsData,
  };
  const weekInitial = formatedLabels.filter((el) => el.includes(publicationDate))?.[0] || null;
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    verticalLine:
      weekInitial === null
        ? undefined
        : {
            xPosition: formatedLabels.filter((el) => el.includes(publicationDate))?.[0] || null,
            color: 'rgba(22, 119, 255, 0.7)',
            label: translate('Published at'),
          },
    title: {
      display: true,
      text: translate('Number of keywords'),
      fontSize: 14,
      position: 'left',
      fontStyle: 'normal',
      fontColor: '#718096',
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            fontSize: 14,
            callback: function (value) {
              return value.split('\n');
            },
          },
          barPercentage: 0.6,
        },
      ],
      yAxes: [
        {
          stacked: false,
          gridLines: {
            display: true,
            borderDash: [2, 2],
            drawBorder: false,
            lineWidth: 2,
          },
          ticks: {
            min: 0,
            // stepSize: 10000,
          },
        },
      ],
    },
  };

  const fetchAccountDomain = async () => {
    try {
      const response = await firebaseHelper.getAccount(currentAccountId);
      setDomain(response.domain);
    } catch (error) {
      console.error('Error fetching account domain:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchAccountDomain();
  }, [user, currentAccountId]);

  return (
    <PerformanceDataStyles>
      {isLoading ? (
        <>
          <Shimmer height="10em" />
          <Shimmer height="30em" />
          <Shimmer height="30em" />
        </>
      ) : hasKeywordMonitoring ? (
        <>
          <div className="d-flex gap-4">
            <div className="card-key-word d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-between">
                <span className="card-title">{translate('Monitored keywords')}</span>
                <div className="d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 5V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5Z"
                      stroke="white"
                      strokeOpacity="0.7"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 18H14"
                      stroke="white"
                      strokeOpacity="0.7"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.5 3V5C14.5 5.27614 14.2761 5.5 14 5.5H10C9.72386 5.5 9.5 5.27614 9.5 5V3"
                      stroke="white"
                      strokeOpacity="0.7"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="card-info">{countKeywords()}</span>
                </div>
              </div>
              <div className="icon-growth d-flex justify-content-center align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.99997 10.414L14 14.414L19.707 8.707L22 11V5H16L18.293 7.293L14 11.586L9.99997 7.586L2.29297 15.293L3.70697 16.707L9.99997 10.414Z"
                    fill="#F7FAFC"
                  />
                </svg>
              </div>
            </div>
            <div className="card-publish-at d-flex justify-content-between">
              {publicationDate ? (
                <div className="d-flex flex-column">
                  <span className="card-title">{translate('Published at')}</span>
                  <span className="card-info">{publicationDate}</span>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center">
                  <span className="card-title">{translate('Awaiting publication')}</span>
                </div>
              )}
              <div className="icon-growth d-flex justify-content-center align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19 4H17V2H15V4H9V2H7V4H5C3.897 4 3 4.897 3 6V8V20C3 21.103 3.897 22 5 22H19C20.103 22 21 21.103 21 20V8V6C21 4.897 20.103 4 19 4ZM19.002 20H5V8H19L19.002 20Z"
                    fill="#F7FAFC"
                  />
                  <path
                    d="M11 17.414L16.707 11.707L15.293 10.293L11 14.586L8.70697 12.293L7.29297 13.707L11 17.414Z"
                    fill="#F7FAFC"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="chart-component d-flex flex-column gap-3">
            <div className="d-flex gap-3">
              {data.datasets
                .slice()
                .reverse()
                .map((dataset, index) => (
                  <div
                    key={`${dataset.label}${index}`}
                    className="chart-subtitle d-flex flex-column"
                  >
                    <div
                      className="chart-subtitle-color"
                      style={{ backgroundColor: dataset.backgroundColor }}
                    />
                    <div className="d-flex flex-column gap-1">
                      <span>{dataset.label}</span>
                      <span>
                        {Array.isArray(dataset.data) && dataset.data.length > 0
                          ? dataset.data.reduce((acc, currentValue) => acc + currentValue, 0) === 0
                            ? '-'
                            : dataset.data.reduce((acc, currentValue) => acc + currentValue, 0)
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            <div style={{ position: 'relative', height: '28.4rem', width: '100%' }}>
              <Bar data={data} options={options} />
            </div>
          </div>
          <PerformanceTable
            data={data}
            performanceData={performanceData}
            publicationDate={publicationDate}
          />
        </>
      ) : (
        <>
          {!domain && (
            <div className="span-warning d-flex gap-2 align-items-center justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <i className="bx bxs-error-circle" style={{ color: '#dd6b20' }} />
                <span className="span-subtitle">
                  {translate(
                    'It is necessary to register the website domain in the account settings.',
                  )}
                </span>
              </div>
              {user.adminStatus === 'Super Admin' && (
                <button
                  className="btn"
                  onClick={() => {
                    window.location.href = `/edit-account?id=${currentAccountId}`;
                  }}
                >
                  {translate('Register')}
                </button>
              )}
            </div>
          )}
          <div className="d-flex flex-column gap-4 align-items-center pt-5 pb-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="184"
              height="114"
              viewBox="0 0 184 114"
              fill="none"
            >
              <path
                d="M25.875 80C9.85688 83.5947 0 88.4916 0 93.8884C0 104.921 41.1898 113.864 92 113.864C142.81 113.864 184 104.921 184 93.8884C184 88.4916 174.143 83.5947 158.125 80V88.708C158.125 94.7664 154.33 99.7317 149.644 99.7317H34.3563C29.67 99.7317 25.875 94.7635 25.875 88.708V80Z"
                fill="black"
                fillOpacity="0.06"
              />
              <path
                d="M119.637 45.3094C119.637 40.7293 122.495 36.9482 126.04 36.9453H158.125V88.7021C158.125 94.7604 154.33 99.7258 149.644 99.7258H34.3562C29.67 99.7258 25.875 94.7576 25.875 88.7021V36.9453H57.96C61.5049 36.9453 64.3626 40.7207 64.3626 45.3008V45.3636C64.3626 49.9437 67.252 53.6421 70.794 53.6421H113.206C116.748 53.6421 119.637 49.9095 119.637 45.3294V45.3094Z"
                fill="black"
                fillOpacity="0.04"
                stroke="#D9D9D9"
              />
              <path
                d="M158.125 37.2642L128.955 4.44147C127.555 2.2042 125.511 0.851562 123.358 0.851562H60.6424C58.489 0.851562 56.4449 2.2042 55.0448 4.43861L25.875 37.2671"
                stroke="#D9D9D9"
              />
            </svg>
            <div className="d-flex gap-2 align-items-center">
              <MoonLoader size={22} color="#1677ff" />
              <span className="span-title">{translate('Collecting data')}</span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <span className="span-subtitle">
                {translate('Data is collected weekly, every Monday at 8:00 (AM).')}
              </span>
              <span className="span-subtitle">
                {translate('The next data collection will take place in')}:&nbsp;
                <span className="span-subtitle bold">{getNextMondayDate()}</span>.
              </span>
            </div>
          </div>
        </>
      )}
    </PerformanceDataStyles>
  );
};

PerformanceData.propTypes = {
  publicationDate: PropTypes.string,
};

export default PerformanceData;
