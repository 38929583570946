import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { getDateByWeekNumber } from 'utils';
import { PerformanceTableStyles } from './styles';

const PerformanceTable = ({ data, performanceData, publicationDate }) => {
  const { t: translate } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [visibleColumns, setVisibleColumns] = useState(3);
  const [countColumn, setCountColumn] = useState(0);
  const tableRef = useRef(null);

  const primaryColumnWidth = 270;
  const secondaryColumnWidth = 160;
  const weeksColumnWidth = 160;

  const slide = (direction) => {
    const table = tableRef.current;
    if (!table) return;

    const scrollAmount =
      direction * (weeksColumnWidth * visibleColumns + primaryColumnWidth + secondaryColumnWidth);

    table.scrollBy({ left: scrollAmount, behavior: 'smooth' });

    setCurrentPage(currentPage + direction);
  };

  const separatePhrase = (phrase) => {
    if (typeof phrase !== 'string') return { label: '', value: '' };
    const [label, value] = phrase.split('\n');

    return { label, value };
  };

  const formatedLabels = performanceData?.map((week) => {
    const date = getDateByWeekNumber(week.week);
    const weekNumber = translate('Week') + ' ' + week.week.split('-')[1];
    return `${weekNumber}\n${date}`;
  });

  const findClosestWeek = (publicationDate, labels) => {
    const publicationTimestamp = new Date(publicationDate.split('/').reverse().join('-')).getTime();

    return (
      labels
        .map((label) => ({
          label,
          timestamp: new Date(label.split('\n')[1].split('/').reverse().join('-')).getTime(),
        }))
        .filter(({ timestamp }) => timestamp >= publicationTimestamp)
        .sort((a, b) => a.timestamp - b.timestamp)[0]?.label || null
    );
  };

  const weekInitial = publicationDate ? findClosestWeek(publicationDate, formatedLabels) : null;
  const { value: weekValue, label: weekLabel } = separatePhrase(weekInitial);

  const keywordsData = performanceData.reduce((acc, week) => {
    const keywords = week.data;
    keywords.forEach((keyword) => {
      const keywordData = {
        keyword: keyword.keyword,
        type: keyword.data.isPrimary ? 'primary' : 'secondary',
        week: week.week,
        value: keyword.data.bestPlace,
      };

      const existingKeyword = acc.find((item) => item.keyword === keyword.keyword);
      if (existingKeyword) {
        existingKeyword.weeks.push({
          week: keywordData.week,
          value: keywordData.value,
        });
      } else {
        acc.push({
          keyword: keywordData.keyword,
          type: keywordData.type,
          weeks: [
            {
              week: keywordData.week,
              value: keywordData.value,
            },
          ],
        });
      }
    });
    return acc;
  }, []);

  const handleDownloadCSV = () => {
    const csvHeader = 'Keyword,Week,Value\n';

    const csvContent = keywordsData
      .map((keyword) =>
        keyword.weeks.map((week) => `${keyword.keyword},${week.week},${week.value}`).join('\n'),
      )
      .join('\n');

    const csvData = csvHeader + csvContent;

    const encodedUri = encodeURI(csvData);

    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedUri);
    link.setAttribute('download', 'keyword_data.csv');
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  useEffect(() => {
    const updateVisibleColumns = () => {
      const table = tableRef.current;
      if (!table) return;

      const tableWidth = table.offsetWidth;
      const newVisibleColumns = Math.floor(tableWidth / weeksColumnWidth);

      setVisibleColumns(newVisibleColumns);
      setCountColumn(Math.ceil((data.labels.length + 1) / newVisibleColumns));
    };
    window.addEventListener('resize', updateVisibleColumns);

    updateVisibleColumns();

    return () => window.removeEventListener('resize', updateVisibleColumns);
  }, [data, tableRef]);

  return (
    <PerformanceTableStyles>
      <div className="table-header d-flex justify-content-between align-items-center">
        <span>{translate('Keyword')}</span>
        <button className="btn-card download next" onClick={handleDownloadCSV}>
          Download CSV
          <i className="bx bx-down-arrow-alt" />
        </button>
      </div>
      <div className="performance-table" ref={tableRef}>
        <Table>
          <thead>
            <tr>
              <th>{translate('Keyword')}</th>
              {formatedLabels
                .slice()
                .reverse()
                .map((labelGroup, index) => {
                  const { label, value } = separatePhrase(labelGroup);
                  const dayBeforePublish = weekInitial ? value === weekValue : false;

                  return (
                    <th
                      key={`${labelGroup}${index}`}
                      style={{ width: '10rem', position: 'relative' }}
                    >
                      {dayBeforePublish && (
                        <>
                          <div
                            style={{
                              height: '100%',
                              width: '1px',
                              backgroundColor: 'rgba(22, 119, 255, 0.7)',
                              position: 'absolute',
                              right: 40,
                              top: 0,
                            }}
                          />
                          <span
                            style={{
                              fontSize: '0.5rem',
                              color: 'rgba(22, 119, 255, 0.7)',
                              position: 'absolute',
                              right: 15,
                              top: 0,
                              zIndex: 9999,
                              background: 'white',
                            }}
                          >
                            {translate('Published at')}
                          </span>
                        </>
                      )}
                      <div className="header-label">{label}</div>
                      <div className="header-value">{value}</div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {keywordsData.map((keyword, index) => {
              return (
                <tr key={`${keyword.keyword}${index}`}>
                  <td className="body-search-keyword">
                    <div className="body-search-tag">
                      {translate(
                        `${keyword.type.charAt(0).toUpperCase()}${keyword.type
                          .slice(1)
                          .toLowerCase()}`,
                      )}
                    </div>
                    <div>{keyword.keyword}</div>
                  </td>
                  {keyword.weeks.reverse().map((week, index) => {
                    const previousWeek = keyword.weeks[index + 1];
                    const total = week?.value - previousWeek?.value;
                    const bool = weekLabel.includes(week.week.split('-')[1]);
                    return (
                      <td
                        key={`${week.value}${index}`}
                        className="body-search-value"
                        style={{
                          position: 'relative',
                        }}
                      >
                        {bool && (
                          <>
                            <div
                              style={{
                                height: '100%',
                                width: '1px',
                                backgroundColor: 'rgba(22, 119, 255, 0.7)',
                                position: 'absolute',
                                right: 40,
                                top: 0,
                              }}
                            />
                          </>
                        )}
                        <div>{week.value === 0 ? '-' : week.value}</div>
                        {previousWeek && week.value === 0 ? (
                          <div className="total-growth total-growth-positive">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                            >
                              <path d="M6 3.5L11 8.5H1L6 3.5Z" fill="#E53E3E" />
                            </svg> */}
                          </div>
                        ) : previousWeek && total < 0 ? (
                          <div className="total-growth total-growth-negative d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                            >
                              <path d="M6 9.5L1 4.5H11L6 9.5Z" fill="#38A169" />
                            </svg>
                            {total}
                          </div>
                        ) : total > 0 ? (
                          <div className="total-growth total-growth-positive">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                            >
                              <path d="M6 3.5L11 8.5H1L6 3.5Z" fill="#E53E3E" />
                            </svg>
                            +{total}
                          </div>
                        ) : (
                          <div style={{ height: '1rem' }} />
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="table-footer d-flex justify-content-end align-items-center">
        <div className="d-flex gap-3 align-items-center">
          <button
            className="btn-card previous"
            onClick={() => slide(-1)}
            disabled={currentPage === 0}
          >
            <i className="bx bx-chevron-left" />
            {translate('Previous')}
          </button>
          <span>{`${translate('Page')} ${currentPage + 1} ${translate('of')} ${countColumn}`}</span>
          <button
            className="btn-card next"
            onClick={() => slide(1)}
            disabled={currentPage === countColumn - 1}
          >
            {translate('Next')}
            <i className="bx bx-chevron-right" />
          </button>
        </div>
      </div>
    </PerformanceTableStyles>
  );
};

PerformanceTable.propTypes = {
  data: PropTypes.object,
  performanceData: PropTypes.array,
  publicationDate: PropTypes.string,
};

export default PerformanceTable;
