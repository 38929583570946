import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, Row } from 'reactstrap';
import { useAuth } from 'hooks/useAuth';
import { getFirebaseBackend } from 'helpers/firebaseHelper';

import { HomepageStyled } from './styles';
import { statusNumber } from '../../constants';
import ContainerShortcuts from './components/Container-Shortcuts';
import ChartBarBasic from './components/Chart-Bar-Basic';
import ChartBarVertical from './components/Chart-Bar-Vertical';
import ChartRadar from './components/Chart-Radar';
import Shimmer from 'components/Shimmer';
import TableLastTasks from './components/Table-Last-Tasks';
import { HomepageNoActivity } from 'assets/icons';

const Homepage = () => {
  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user?.account;
  const userName = user?.name;
  const [isLoading, setIsLoading] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [tasksByColumn, setTasksByColumn] = useState([]);
  const [tasksByStatus, setTasksByStatus] = useState([]);
  const [activitiesImplemented, setActivitiesImplemented] = useState([]);
  const [activitiesDelivered, setActivitiesDelivered] = useState([]);
  const [tasksByFoundations, setTasksByFoundations] = useState([]);
  const [lastTasksAdded, setLastTasksAdded] = useState([]);
  const [accountModules, setAccountModules] = useState({});
  const [hasAllowedShortcut, setHasAllowedShortcut] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const fetchData = async () => {
    try {
      setNoResults(false);
      setIsLoading(true);

      const response = await firebaseHelper.getAllTasks(accountId);

      if (response === null) {
        setNoResults(true);
        setIsLoading(false);
        return;
      }

      const fixedColumns = [
        'Backlog',
        'In Progress',
        'Pendings',
        'Pending',
        'Delivered',
        'Finished',
        'Archived',
      ];

      const fixedStatus = [
        'Waiting',
        'Ready to Implement',
        'Technical Impediment',
        'Under Development',
        'Implemented',
      ];

      const dataColumn = [];
      const dataStatus = [];
      const dataFoundations = [];

      Object.values(response).map((task) => {
        const item = task.column;
        const statusItem = statusNumber[task.status];
        const foundations = task.foundations;

        if (!dataColumn.some((data) => data.item === item)) {
          dataColumn.push({ item, total: 1 });
        } else {
          const index = dataColumn.findIndex((data) => data.item === item);
          dataColumn[index].total += 1;
        }
        if (!dataStatus.some((data) => data.item === statusItem)) {
          dataStatus.push({ item: statusItem, total: 1 });
        } else {
          const index = dataStatus.findIndex((data) => data.item === statusItem);
          dataStatus[index].total += 1;
        }

        foundations.forEach((foundation) => {
          if (!dataFoundations.some((data) => data.item === foundation)) {
            dataFoundations.push({ item: foundation, total: 1 });
          } else {
            const index = dataFoundations.findIndex((data) => data.item === foundation);
            dataFoundations[index].total += 1;
          }
        });
      });

      fixedColumns.forEach((column) => {
        if (!dataColumn.some((data) => data.item === column)) {
          dataColumn.push({ item: column, total: 0 });
        }
      });

      fixedStatus.forEach((status) => {
        if (!dataStatus.some((data) => data.item === status)) {
          dataStatus.push({ item: status, total: 0 });
        }
      });

      const dataFoundationsWithPercent = dataFoundations.map((item) => ({
        ...item,
        percent: Math.round((item.total * 100) / Object.values(response).length),
      }));

      dataColumn.sort((a, b) => {
        const columnOrder = [
          'Backlog',
          'In Progress',
          'Pendings',
          'Pending',
          'Delivered',
          'Finished',
          'Archived',
        ];
        return columnOrder.indexOf(a.item) - columnOrder.indexOf(b.item);
      });

      dataStatus.sort((a, b) => {
        const statusOrder = [
          'Waiting',
          'Ready to Implement',
          'Technical Impediment',
          'Under Development',
          'Implemented',
        ];
        return statusOrder.indexOf(a.item) - statusOrder.indexOf(b.item);
      });

      const dataActivitiesDelivered = [];
      Object.values(response).map((task) => {
        if (task.dateDelivery) {
          const month = new Date(task.dateDelivery).getMonth();
          const year = new Date(task.dateDelivery).getFullYear();

          const existingData = dataActivitiesDelivered.find(
            (data) => data.month === month && data.year === year,
          );
          if (existingData) {
            existingData.count += 1;
          } else {
            dataActivitiesDelivered.push({
              month: month,
              year: year,
              count: 1,
            });
          }
        }
      });

      const dataActivitiesImplemented = [];
      Object.values(response).map((task) => {
        if (task.status === 4) {
          if (task.dateImplementation) {
            const month = new Date(task.dateImplementation).getMonth();
            const year = new Date(task.dateImplementation).getFullYear();
            const existingData = dataActivitiesImplemented.find(
              (data) => data.month === month && data.year === year,
            );
            if (existingData) {
              existingData.count += 1;
            } else {
              dataActivitiesImplemented.push({
                month: month,
                year: year,
                count: 1,
              });
            }
          }
        }
      });

      const last4TasksAdded = Object.values(response).slice(-4).reverse();

      setTasks(Object.values(response));
      setTasksByColumn(dataColumn);
      setTasksByStatus(dataStatus);
      setActivitiesImplemented(dataActivitiesImplemented);
      setActivitiesDelivered(dataActivitiesDelivered);
      setTasksByFoundations(dataFoundationsWithPercent);
      setLastTasksAdded(last4TasksAdded);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAccountModules = async () => {
    const accountObj = await firebaseHelper.getAccount(user?.account);
    setAccountModules(accountObj.modules);
  };

  const handleHasAllowedShortcut = () => {
    const allowedShortcut = [
      (user?.modules?.tasks && accountModules?.tasks) || user.adminStatus === 'Super Admin',
      user.adminStatus === 'Super Admin',
      user.adminStatus === 'Super Admin' || user.adminStatus === 'Admin',
    ];
    setHasAllowedShortcut(allowedShortcut.some((item) => item));
  };

  useEffect(() => {
    fetchAccountModules();
    handleHasAllowedShortcut();
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [accountId]);

  return (
    <HomepageStyled>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Home')} | Ectools`}</title>
        </MetaTags>
        <Row>
          <div className="page-title d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">{`${translate('Hello')} ${userName}`}</h4>
            {hasAllowedShortcut && (
              <div className="page-title-right">
                <Button
                  className="btn-toggler d-flex align-items-center justify-content-between"
                  onClick={toggle}
                >
                  {isOpen ? (
                    <>
                      {translate('Hide shortcuts')} <i className="bx bx-chevron-up" />
                    </>
                  ) : (
                    <>
                      {translate('Show shortcuts')} <i className="bx bx-chevron-down" />
                    </>
                  )}
                </Button>
              </div>
            )}
          </div>
        </Row>
        {hasAllowedShortcut && (
          <Collapse isOpen={isOpen}>
            <ContainerShortcuts user={user} accountModules={accountModules} />
          </Collapse>
        )}
        {noResults ? (
          <div className="d-flex flex-column mt-4 align-items-center gap-4 rounded-3 bg-white no-activity">
            <HomepageNoActivity />
            <div className="d-flex flex-column gap-2 align-items-center">
              <p>{translate('Project in structuring')}</p>
              <span>
                {translate(
                  'We are analyzing your project information and data to configure activities.',
                )}
              </span>
            </div>
          </div>
        ) : (
          <div className="mt-4 d-flex flex-column gap-3">
            {isLoading ? (
              <div className="d-flex p-2rem align-items-start gap-4 align-self-stretch rounded-3 bg-white">
                <Shimmer height="35em" />
              </div>
            ) : (
              <div className="d-flex p-2rem align-items-start gap-4 align-self-stretch rounded-3 bg-white">
                <ChartBarBasic
                  title={translate('Guidance status')}
                  data={tasksByColumn}
                  totalCount={tasks.length}
                  color="#50a5f1"
                />
                <ChartBarBasic
                  title={translate('Task status')}
                  data={tasksByStatus}
                  totalCount={tasks.length}
                  color="#7367f0"
                />
              </div>
            )}
            {isLoading ? (
              <div className="d-flex p-2rem align-items-start gap-4 align-self-stretch rounded-3 bg-white">
                <Shimmer height="35em" />
              </div>
            ) : (
              <div className="d-flex p-2rem align-items-start gap-4 align-self-stretch rounded-3 bg-white">
                <ChartBarVertical
                  data={activitiesImplemented}
                  secondaryData={activitiesDelivered}
                />
                <ChartRadar data={tasksByFoundations} />
              </div>
            )}
            {isLoading ? (
              <div className="d-flex p-2rem align-items-start gap-4 align-self-stretch rounded-3 bg-white">
                <Shimmer height="35em" />
              </div>
            ) : (
              <div className="d-flex p-2rem align-items-start gap-4 align-self-stretch rounded-3 bg-white">
                <TableLastTasks data={lastTasksAdded} />
              </div>
            )}
          </div>
        )}
      </div>
    </HomepageStyled>
  );
};

export default Homepage;
