export const verticalLinePlugin = {
  id: 'verticalLinePlugin',
  afterDraw(chart) {
    if (chart.options.verticalLine) {
      const ctx = chart.ctx;
      const {
        xPosition,
        color = '#1677ff',
        label = '',
        fontSize = 12,
      } = chart.options.verticalLine;
      const xScale = chart.scales['x-axis-0'];
      const yScale = chart.scales['y-axis-0'];
      const xPixel = xScale.getPixelForValue(xPosition);
      const yAxis = chart.scales['y-axis-0'];
      const yTop = yScale.top;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(xPixel - 35, yTop + 7);
      ctx.lineTo(xPixel - 35, yAxis.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = color;
      ctx.stroke();
      ctx.restore();
      if (label) {
        ctx.save();
        ctx.font = `${fontSize}px Arial`;
        ctx.fillStyle = color;
        ctx.textAlign = 'center';
        ctx.fillText(label, xPixel - 35, yAxis.top + 5); // Position above the chart
        ctx.restore();
      }
    }
  },
};
