import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-datepicker/dist/react-datepicker.css';

import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import SelectInput from '../../components/Select-Input';
import DateInput from '../../components/Date-Input';
import SpinnerInput from '../../components/Spinner-Input';
import Breadcrumbs from '../../components/Breadcrumb';
import { getFormValue, normalizeCode, copyToClipBoard } from '../../utils/';
import CardInputGoogleDrive from './components/Card-Input-Google-Drive';
import CardInputCMS from './components/Card-Input-CMS';
import Shimmer from '../../components/Shimmer';

const EditAccount = () => {
  const formConfig = {
    defaultValues: {
      id: '',
      createdAt: '',
      name: '',
      status: [],
      plan: [],
      beginDate: '',
      contractTime: '',
      onetime: false,
      services: [],
      modules: [],
      hours: '',
      users: '',
      keywords: '',
      urls: '',
      gscLink: '',
      gaLink: '',
      internalTeam: [],
      approvalTeam: [],
      contactPoint: [],
      gDriveFolder: '',
      cmsAuth: { link: '', login: '', base64: '', type: '', key: '' },
    },
    shouldFocusError: true,
  };
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const accountParam = searchParams.get('id');
  const form = useForm(formConfig);
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    clearErrors,
    setValue,
    getValues,
  } = form;
  const { t: translate } = useTranslation();
  const firebaseHelper = getFirebaseBackend();
  const [file, setFile] = useState(null);
  const [updateSuccessfull, setUpdateSuccessfull] = useState(true);
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [selectKey, setSelectKey] = useState(0);
  const [cmsType, setCmsType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const min = Math.ceil(10000);
  const max = Math.floor(99999);
  const [num1, setNum1] = useState(Math.floor(Math.random() * (max - min + 1)) + min);
  const [num2, setNum2] = useState(Math.floor(Math.random() * (max - min + 1)) + min);
  let code = `${normalizeCode(watch('name'))}-${num1}-${num2}`;

  const onSubmit = async (data) => {
    setIsSending(true);

    const newFile = file !== null;
    // Makes a copy of the beginDate object to calculate its ending
    const endDate = new Date(+data.beginDate);
    const creditDifference = data.keywords - data.oldCredits;
    const newRealCredits = data.realCredits + creditDifference;
    const newShownCredits = data.shownCredits + creditDifference;

    let base64 = null;
    if (cmsType === 'WORDPRESS') {
      base64 = Buffer.from(`${data.cmsAuth.link}:${data.cmsAuth.password}`).toString('base64');
    }

    const account = {
      id: data.id,
      code,
      createdAt: data.createdAt,
      realCredits: newRealCredits > 0 ? newRealCredits : 0,
      shownCredits: newShownCredits > 0 ? newShownCredits : 0,
      name: data.name,
      domain: data.domain,
      status: data.status.value,
      plan: data.plan.value,
      hasLogo: data.hasLogo || newFile,
      beginDate: data.beginDate ? Date.parse(data.beginDate) : null,
      endDate:
        data.contractTime !== 0 ? endDate.setMonth(endDate.getMonth() + data.contractTime) : null,
      contractTime: data.contractTime,
      onetime: data.onetime,
      services: data.services.map(getFormValue),
      modules: data.modules.reduce((acc, cur) => ({ ...acc, [cur.value]: true }), {}),
      limits: {
        hours: data.hours,
        users: data.users,
        credits: data.keywords,
        urls: data.urls,
      },
      gscLink: data.gscLink || null,
      gaLink: data.gaLink || null,
      internalTeam: data.internalTeam.map(getFormValue),
      approvalTeam: data.approvalTeam.map(getFormValue),
      contactPoint: data.contactPoint || null,
      gDriveFolder: data.gDriveFolder || null,
      cmsAuth: {
        link: data.cmsAuth.link || null,
        login: data.cmsAuth.login || null,
        base64: base64 ? base64 : null,
        type: cmsType || null,
        key: data.cmsAuth.key || null,
      },
    };

    try {
      await firebaseHelper.updateAccount(account.id, account);

      if (data.status.value) {
        await firebaseHelper.activateAccount(account.id);
      }
      if (!data.status.value) {
        await firebaseHelper.deactivateAccount(account.id);
      }
      if (newFile) {
        await firebaseHelper.updateAccountLogo(file, account.id);
      }
      setUpdateSuccessfull(true);
      setIsSending(false);
      setIsSaved(true);
      setModal(true);
    } catch (error) {
      setIsSending(false);
      setIsSaved(false);
      console.error(error);
      setErrorMessage('An error occurred while trying to update the account.');
      setErrorModal(true);
    }
  };

  const onInvalid = (errors) => {
    if (Object.values(errors).length || !updateSuccessfull) {
      setUpdateSuccessfull(false);
      console.error('Error saving changes');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
  };

  const handleFileSubmit = (event) => {
    if (!event?.target?.files[0]) {
      return;
    }
    if (
      !(
        event?.target?.files[0].type === 'image/png' ||
        event?.target?.files[0].type === 'image/jpg' ||
        event?.target?.files[0].type === 'image/jpeg'
      )
    ) {
      alert(
        translate(
          'The profile photo file extesion needs to be one of the following: png, jpg, jpeg.',
        ),
      );
    } else {
      setFile(event?.target?.files[0]);
    }
  };

  const closeModalHandle = () => {
    setFile([]);
    setModal(false);
  };

  const randomizeCode = () => {
    let newNum1 = Math.floor(Math.random() * (max - min + 1)) + min;
    let newNum2 = Math.floor(Math.random() * (max - min + 1)) + min;
    while (newNum1 === num1) {
      newNum1 = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    while (newNum2 === num2) {
      newNum2 = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    setNum1(newNum1);
    setNum2(newNum2);
  };

  const setCode = (code) => {
    const firstNum = code.slice(-11, -6);
    const secondNum = code.slice(-5);
    setNum1(firstNum);
    setNum2(secondNum);
  };

  const getInternalTeam = () => {
    return new Promise(async (resolve, reject) => {
      const ectoTeam = await firebaseHelper.getTeam(process.env.REACT_APP_ECTO_ID);
      const select = Object.entries(ectoTeam).map(([accountId, memberInfo]) => ({
        value: memberInfo.id,
        label: memberInfo.name,
      }));
      resolve(select);
    });
  };

  const getApprovalTeam = () => {
    return new Promise(async (resolve, reject) => {
      const approvalTeam = teamData.filter(
        (user) =>
          user.adminStatus === 'Client' ||
          user.adminStatus === 'Client Admin' ||
          user.adminStatus === 'Super Admin',
      );
      const ectoTeam = await firebaseHelper.getTeam(process.env.REACT_APP_ECTO_ID);

      const selectTeamData = Object.entries(approvalTeam).map(([accountId, memberInfo]) => ({
        value: memberInfo.id,
        label: memberInfo.name,
      }));
      const selectEctoTeam = Object.entries(ectoTeam).map(([accountId, memberInfo]) => ({
        value: memberInfo.id,
        label: memberInfo.name,
      }));

      const allSelects = selectTeamData.concat(selectEctoTeam);
      const uniqueSelects = [];
      const userIds = new Set();
      allSelects.forEach((user) => {
        if (!userIds.has(user.value)) {
          uniqueSelects.push(user);
          userIds.add(user.value);
        }
      });
      resolve(uniqueSelects);
    });
  };

  const getAccountTeam = () => {
    return new Promise(async (resolve, reject) => {
      const team = await firebaseHelper.getTeam(accountParam);
      if (team) {
        const select = Object.entries(team)
          .filter(([memberId]) => memberId !== 'initialTeamState')
          .map(([accountId, memberInfo]) => ({
            value: memberInfo.id,
            label: memberInfo.name,
          }));
        resolve(select);
      }
    });
  };

  const fetchTeamTable = async () => {
    const team = await firebaseHelper.getTeam(accountParam);
    if (team) {
      const processedTeam = await Promise.all(
        Object.values(team)
          .filter((user) => user.id !== 'initialTeamState')
          .map(async (user) => {
            const userData = await firebaseHelper.getUser(user.id);
            return {
              id: user.id,
              name: user.name,
              email: userData.email,
              adminStatus: userData.adminStatus,
            };
          }),
      );
      setTeamData(processedTeam);
    }
  };

  const columns = [
    {
      dataField: 'name',
      text: translate('Name'),
      sort: true,
    },
    {
      dataField: 'email',
      text: translate('Email'),
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'asc',
    },
  ];

  const sizePerPageList = [{ text: '10', value: 10 }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: teamData.length,
    custom: true,
    sizePerPageList,
  };

  useEffect(async () => {
    try {
      setIsSending(false);
      setIsLoading(true);
      const account = await firebaseHelper.getAccount(accountParam);

      const processedServices = account.services.map((service) => ({
        value: service,
        label: translate(service),
      }));
      const processedModules = Object.keys(account.modules).map((key) => {
        const capitalized = key.charAt(0).toUpperCase() + key.slice(1);
        return { value: key, label: translate(capitalized) };
      });
      const processedInternalTeam = await Promise.all(
        account.internalTeam.map(async (personId) => ({
          value: personId,
          label: await firebaseHelper.userName(personId),
        })),
      );
      const processedApprovalTeam = await Promise.all(
        (account.approvalTeam || []).map(async (personId) => ({
          value: personId,
          label: await firebaseHelper.userName(personId),
        })),
      );

      reset({
        id: account.id,
        createdAt: account.createdAt,
        realCredits: account.realCredits,
        shownCredits: account.shownCredits,
        oldCredits: account.limits.keywords,
        hasLogo: account.hasLogo,
        name: account.name,
        domain: account.domain,
        status: {
          value: account.status,
          label: translate(account.status ? 'Activated' : 'Deactivated'),
        },
        plan: { value: account.plan, label: translate(account.plan) },
        beginDate: account.beginDate ? new Date(account.beginDate) : '',
        contractTime: account.contractTime,
        onetime: account.onetime,
        services: processedServices,
        modules: processedModules,
        hours: account.limits.hours,
        users: account.limits.users,
        keywords: account.limits.credits,
        urls: account.limits.urls,
        internalTeam: processedInternalTeam,
        approvalTeam: processedApprovalTeam,
        gscLink: account.gscLink ?? '',
        gaLink: account.gaLink ?? '',
        contactPoint: account.contactPoint,
        gDriveFolder: account.gDriveFolder ?? '',
        cmsAuth: {
          link: account.cmsAuth?.link ?? '',
          login: account.cmsAuth?.login ?? '',
          base64: account.cmsAuth?.base64 ?? '',
          type: account.cmsAuth?.type ?? '',
          key: account.cmsAuth?.key ?? '',
        },
      });
      if (account.cmsAuth?.base64 || account.cmsAuth?.key) {
        setIsSaved(true);
      }
      setCmsType(account.cmsAuth);
      setCode(account.code);
      setUpdateSuccessfull(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      if (error === "This account doesn't exist") {
        setErrorMessage(
          "This account doesn't exist! Return to the Account Listing to try to edit another account.",
        );
      } else {
        setErrorMessage('Return to the Account Listing to try to edit another account.');
      }
      setErrorModal(true);
    }
  }, []);

  useEffect(() => {
    fetchTeamTable();
  }, []);

  useEffect(() => {
    if (!teamData) {
      return;
    }
    setSelectKey((prevState) => prevState + 1);
  }, [teamData]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{translate('Edit Account')} | Ectools</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title={translate('Accounts')} breadcrumbItem={translate('Edit')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody style={{ position: 'relative' }}>
                  <Modal
                    isOpen={modal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                  >
                    <ModalHeader>
                      <div className="modal--header">
                        <i className="bx bx-check-double modal--icon" />
                        <span className="modal--title">
                          {translate('Account Updated Successfully!')}
                        </span>
                      </div>
                    </ModalHeader>
                    <ModalBody>
                      <p>
                        {translate(
                          'The Account was successfully updated. See the accounts to view your changes.',
                        )}
                      </p>
                    </ModalBody>
                    <ModalFooter>
                      <Link to="/list-accounts">
                        <button className="modal--button-two">{translate('See Accounts')}</button>
                      </Link>
                      <button className="modal--button-one" onClick={closeModalHandle}>
                        {translate('Close')}
                      </button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={errorModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                  >
                    <ModalHeader>
                      <div className="modal--header">
                        <i className="bx bx-error-circle modal--icon" style={{ color: 'red' }} />
                        <span className="modal--title">
                          {translate('An Unexpected Error Occured!')}
                        </span>
                      </div>
                    </ModalHeader>
                    <ModalBody>
                      <p>{translate(errorMessage)}</p>
                    </ModalBody>
                    <ModalFooter>
                      <Link to="/list-accounts">
                        <button className="modal--button-two">{translate('Return')}</button>
                      </Link>
                    </ModalFooter>
                  </Modal>
                  <CardTitle className="mb-2">{translate('Edit Existing Account')}</CardTitle>

                  <FormProvider {...form}>
                    <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        {isLoading ? (
                          <Shimmer width="49%" height="65em" />
                        ) : (
                          <div style={{ width: '49%' }}>
                            <LabelGroup
                              htmlFor="name"
                              label="Account's Name"
                              mb={1}
                              column
                              required
                            >
                              <TextInput
                                controlName="name"
                                placeholder="Name"
                                validation={{
                                  pattern: {
                                    value: /[A-Za-z_]/,
                                    message: 'Name must contain at least a letter!',
                                  },
                                }}
                                noEllipsis
                                required
                              />
                              {errors.name && (
                                <span style={{ color: 'red' }}>
                                  {translate(errors.name.message)}
                                </span>
                              )}
                            </LabelGroup>
                            <LabelGroup htmlFor="domain" label="Domain" mb={1} column required>
                              <TextInput
                                controlName="domain"
                                placeholder=""
                                validation={{
                                  pattern: {
                                    value: /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,63}$/,
                                    message: 'Enter a valid domain!',
                                  },
                                }}
                                noEllipsis
                                required
                              />
                              {errors.domain && (
                                <span style={{ color: 'red' }}>
                                  {translate(errors.domain.message)}
                                </span>
                              )}
                            </LabelGroup>

                            <LabelGroup htmlFor="status" label="Status" mb={1} column required>
                              <SelectInput
                                controlName="status"
                                options={[
                                  { value: true, label: translate('Activated') },
                                  {
                                    value: false,
                                    label: translate('Deactivated'),
                                  },
                                ]}
                                required
                              />
                              {errors.status && (
                                <span style={{ color: 'red' }}>
                                  {translate(errors.status.message)}
                                </span>
                              )}
                            </LabelGroup>

                            <LabelGroup htmlFor="plan" label="Plan" mb={1} column required>
                              <SelectInput
                                controlName="plan"
                                options={[
                                  {
                                    value: 'Start',
                                    label: translate('Start Plan'),
                                  },
                                  { value: 'Pro', label: translate('Pro Plan') },
                                  {
                                    value: 'Enterprise',
                                    label: translate('Enterprise Plan'),
                                  },
                                  {
                                    value: 'Personalized',
                                    label: translate('Personalized Plan'),
                                  },
                                ]}
                                required
                              />
                              {errors.plan && (
                                <span style={{ color: 'red' }}>
                                  {translate(errors.plan.message)}
                                </span>
                              )}
                            </LabelGroup>

                            <LabelGroup htmlFor="file-input" label="Logo" mb={1} column>
                              <Input
                                id="file-input"
                                className="form-control"
                                type="file"
                                onChange={handleFileSubmit}
                                accept="image/png, image/jpg, image/jpeg"
                              />
                            </LabelGroup>

                            <LabelGroup
                              htmlFor="beginDate"
                              label="Begin Date"
                              mb={1}
                              column
                              required
                            >
                              <DateInput
                                controlName="beginDate"
                                placeholder="Account's Beginning Date"
                              />
                              {errors.beginDate && (
                                <span style={{ color: 'red' }}>
                                  {translate(errors.beginDate.message)}
                                </span>
                              )}
                            </LabelGroup>

                            <LabelGroup
                              htmlFor="contractTime"
                              label="Contract Time (months)"
                              mb={1}
                              column
                              required
                            >
                              <div className="d-flex flex-row">
                                <SpinnerInput
                                  controlName="contractTime"
                                  size={4}
                                  min={1}
                                  required
                                />
                                <Controller
                                  control={control}
                                  name="onetime"
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      style={{
                                        alignSelf: 'center',
                                        marginLeft: '1em',
                                      }}
                                      name="onetime"
                                      type="checkbox"
                                    />
                                  )}
                                />
                                <label
                                  style={{
                                    margin: '0 0 0 1em',
                                    alignSelf: 'center',
                                  }}
                                  htmlFor="onetime"
                                >
                                  {translate('This is a one-time contract or without renovation')}
                                </label>
                              </div>
                              {errors.contractTime && (
                                <span style={{ color: 'red' }}>
                                  {translate(errors.contractTime.message)}
                                </span>
                              )}
                            </LabelGroup>

                            <LabelGroup
                              htmlFor="services"
                              label="Contracted Services"
                              mb={1}
                              column
                              required
                            >
                              <SelectInput
                                controlName="services"
                                isMulti
                                options={[
                                  {
                                    value: 'SEO Consultancy',
                                    label: translate('SEO Consultancy'),
                                  },
                                  {
                                    value: 'SEO Training',
                                    label: translate('SEO Training'),
                                  },
                                  {
                                    value: 'Local SEO',
                                    label: translate('Local SEO'),
                                  },
                                  {
                                    value: 'SEO for Apps (ASO)',
                                    label: translate('SEO for Apps (ASO)'),
                                  },
                                  {
                                    value: 'SEO for Youtube',
                                    label: translate('SEO for Youtube'),
                                  },
                                  {
                                    value: 'Migration',
                                    label: translate('Migration'),
                                  },
                                ]}
                                required
                              />
                              {errors.services && (
                                <span style={{ color: 'red' }}>
                                  {translate(errors.services.message)}
                                </span>
                              )}
                            </LabelGroup>

                            <LabelGroup
                              htmlFor="modules"
                              label="Contracted Modules"
                              mb={1}
                              column
                              required
                            >
                              <SelectInput
                                controlName="modules"
                                isMulti
                                options={[
                                  { value: 'tasks', label: translate('Tasks') },
                                  {
                                    value: 'content',
                                    label: translate('Content'),
                                  },
                                  {
                                    value: 'courses',
                                    label: translate('Courses'),
                                  },
                                ]}
                                required
                              />
                              {errors.modules && (
                                <span style={{ color: 'red' }}>
                                  {translate(errors.modules.message)}
                                </span>
                              )}
                            </LabelGroup>

                            <LabelGroup htmlFor="limits" label="Limits" mb={1} column>
                              <SpinnerInput
                                controlName="hours"
                                label="Allocated Hours"
                                size={12}
                                min={1}
                                mb={2}
                              />
                              <SpinnerInput
                                controlName="users"
                                label="Users"
                                size={12}
                                min={1}
                                mb={2}
                              />
                              <SpinnerInput
                                controlName="keywords"
                                label="Credits"
                                size={12}
                                min={1}
                                mb={2}
                              />
                              <SpinnerInput
                                controlName="urls"
                                label="Monitored URLs"
                                size={12}
                                min={1}
                                mb={2}
                              />
                            </LabelGroup>
                            <CardInputGoogleDrive accountId={accountParam} watch={watch} />
                          </div>
                        )}

                        <div
                          style={{
                            height: 'auto',
                            width: '1px',
                            backgroundColor: '#E2E2E2',
                          }}
                        />

                        {isLoading ? (
                          <Shimmer width="49%" height="65em" />
                        ) : (
                          <div style={{ width: '49%' }}>
                            <FormGroup className="mb-1" row>
                              <Col lg="11">
                                <Label className="col-form-label col-lg-12">
                                  {translate('Client Code')}
                                </Label>
                                <div style={{ display: 'flex' }}>
                                  <Col lg="9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly
                                      value={code}
                                    />
                                  </Col>
                                  <Col
                                    lg="3"
                                    style={{
                                      display: 'flex',
                                      boxSizing: 'border-box',
                                    }}
                                  >
                                    <Button
                                      style={{
                                        width: '4em',
                                        marginLeft: '1.5em',
                                        padding: '0 0',
                                        backgroundColor: '#F1B44C',
                                        border: 'none',
                                      }}
                                      onClick={randomizeCode}
                                    >
                                      <i className="bx bx-repost" style={{ fontSize: '1.5rem' }} />
                                    </Button>
                                    <Button
                                      style={{
                                        width: '4em',
                                        marginLeft: '1.5em',
                                        padding: '0 0',
                                        backgroundColor: '#596FDE',
                                        border: 'none',
                                      }}
                                      onClick={(e) => copyToClipBoard(e, code)}
                                    >
                                      <i className="bx bx-copy" style={{ fontSize: '1.5rem' }} />
                                    </Button>
                                  </Col>
                                </div>
                              </Col>
                            </FormGroup>

                            <LabelGroup htmlFor="contactPoint" label="Contact Point" mb={1} column>
                              <SelectInput
                                asyncronous
                                isMulti
                                controlName="contactPoint"
                                cacheOptions
                                defaultOptions
                                loadOptions={getAccountTeam}
                              />
                            </LabelGroup>

                            <LabelGroup
                              htmlFor="gscLink"
                              label="Google Search Console Link"
                              mb={1}
                              column
                            >
                              <TextInput controlName="gscLink" placeholder="" noEllipsis />
                            </LabelGroup>

                            <LabelGroup
                              htmlFor="gaLink"
                              label="Google Analytics Link"
                              mb={1}
                              column
                            >
                              <TextInput controlName="gaLink" placeholder="" noEllipsis />
                            </LabelGroup>

                            <LabelGroup
                              htmlFor="internalTeam"
                              label="Ecto's Internal Team"
                              mb={1}
                              column
                              required
                            >
                              <SelectInput
                                asyncronous
                                isMulti
                                controlName="internalTeam"
                                cacheOptions
                                defaultOptions
                                loadOptions={getInternalTeam}
                                required
                              />
                              {errors.internalTeam && (
                                <span style={{ color: 'red' }}>
                                  {translate(errors.internalTeam.message)}
                                </span>
                              )}
                            </LabelGroup>

                            <LabelGroup htmlFor="approvalTeam" label="Approval Team" mb={1} column>
                              <SelectInput
                                key={selectKey}
                                asyncronous
                                isMulti
                                controlName="approvalTeam"
                                cacheOptions
                                defaultOptions
                                loadOptions={getApprovalTeam}
                              />
                            </LabelGroup>

                            <FormGroup className="mb-1" row>
                              <Col lg="11">
                                <Label className="col-form-label col-lg-12">
                                  {translate('Team')}
                                </Label>
                                <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField="id"
                                  columns={columns}
                                  data={teamData}
                                >
                                  {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                      keyField="id"
                                      columns={columns}
                                      data={teamData}
                                      search
                                    >
                                      {(toolkitProps) => (
                                        <React.Fragment>
                                          <Row>
                                            <Col xl="12">
                                              <div className="table-responsive">
                                                <BootstrapTable
                                                  keyField={'id'}
                                                  responsive
                                                  hover
                                                  bordered={false}
                                                  striped={false}
                                                  defaultSorted={defaultSorted}
                                                  classes={'table align-middle table-nowrap'}
                                                  headerWrapperClasses={'thead-light'}
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                />
                                              </div>
                                            </Col>
                                          </Row>

                                          <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                              <div className="d-inline">
                                                <SizePerPageDropdownStandalone
                                                  {...paginationProps}
                                                />
                                              </div>
                                              <div className="text-md-right ms-auto">
                                                <PaginationListStandalone {...paginationProps} />
                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  )}
                                </PaginationProvider>
                                <CardInputCMS
                                  accountId={accountParam}
                                  watch={watch}
                                  setValue={setValue}
                                  getValues={getValues}
                                  cmsType={cmsType}
                                  setCmsType={setCmsType}
                                  errors={errors}
                                  clearErrors={clearErrors}
                                  isSaved={isSaved}
                                  setIsSaved={setIsSaved}
                                />
                              </Col>
                            </FormGroup>
                          </div>
                        )}
                      </div>

                      <Button
                        type="submit"
                        className="mt-3"
                        style={{
                          backgroundColor: '#34C38F',
                          border: 'none',
                          minWidth: '10rem',
                          minHeight: '2.5rem',
                          maxHeight: '2.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 0,
                          position: 'relative',
                        }}
                      >
                        {isSending ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                            style={{
                              width: '1.5rem',
                              height: '1.5rem',
                              position: 'absolute',
                            }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          translate('Save changes')
                        )}
                      </Button>
                    </form>
                  </FormProvider>
                  <div
                    style={{
                      display: updateSuccessfull === false ? 'flex' : 'none',
                      flexDirection: 'column',
                      alignItems: 'center',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      border: '1px solid #F46A6A',
                      borderRadius: '4px',
                      overflow: 'hidden',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <Alert
                      color="light"
                      style={{
                        border: 'none',
                        borderRadius: 0,
                        margin: 0,
                        width: '100%',
                        borderBottom: '1px solid #EAD2D6',
                        padding: '12px',
                      }}
                    >
                      {translate('Unable to save changes')}
                    </Alert>
                    <Alert
                      color="danger"
                      style={{
                        border: 'none',
                        borderRadius: 0,
                        margin: 0,
                        padding: '12px',
                        color: '#000',
                        fontWeight: 500,
                        paddingRight: '48px',
                      }}
                    >
                      {translate('One or more required fields are empty')}
                    </Alert>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EditAccount.propTypes = {
  location: PropTypes.object,
  search: PropTypes.string,
};

export default EditAccount;
