import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { MetaTags } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { Button, Nav, NavItem, TabContent, TabPane } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumb';
import ApprovalChat from './components/Approval-Chat';
import PageLoading from './components/Page-Loading';
import TableResumeReview from './components/Table-Resume-Review';
import ContainerEditContent from './components/Container-Edit-Content';
import { SendCardLayout } from './components/Send-Card/styles';
import ModalApprove from './components/Modal-Approve';
import { GuidelineLayout } from './styles';
import Shimmer from 'components/Shimmer';
import PerformanceData from './components/Performance-Data';
import TitleGuideline from './components/Title-Guideline';

const ContentApproval = () => {
  const firebaseHelper = getFirebaseBackend();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const { t: translate } = useTranslation();
  const [content, setContent] = useState();
  const [isTasksCompleted, setIsTasksCompleted] = useState(false);
  const [activeNav, setActiveNav] = useState('1');
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [modalApprove, setModalApprove] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    const loadedGuideline = await firebaseHelper.getFirebaseGuideline(currentAccountId, id);

    if (loadedGuideline.column !== 'CONTENT_APPROVAL') navigate(`/guideline/${id}`);

    setContent(loadedGuideline);
    setIsLoading(false);
  };

  const toggleModalApprove = () => setModalApprove(!modalApprove);

  useEffect(() => {
    fetchData();
  }, [currentAccountId]);

  useEffect(() => {
    if (!isPageLoading) return;

    const db = getDatabase();
    const guidelineRef = ref(db, `guidelines/${currentAccountId}/${id}`);

    const unsubscribe = onValue(guidelineRef, async (snapshot) => {
      const data = snapshot.val();

      if (!data?.processing) {
        navigate(`/guideline/${id}`);
      }
    });

    return () => {
      off(guidelineRef, 'value', unsubscribe);
    };
  }, [isPageLoading]);

  return (
    <GuidelineLayout>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Content approval')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs title={translate('Content')} breadcrumbItem={translate('Content approval')} />
        {isPageLoading ? (
          <PageLoading
            title={translate('Recreating content')}
            description={translate(
              'This may take a few minutes, you can also wait for this process in the background and return later.',
            )}
            button={translate('Go back')}
            onClick={() => navigate('/guidelines')}
          />
        ) : (
          <div className="container-row">
            <div className="container-general-information">
              <div className="header-general-information">
                <div>
                  <button
                    aria-label="Back"
                    type="button"
                    id="back-button"
                    className="back-button"
                    onClick={() => navigate('/guidelines')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.21875 7.33117H13.3334V8.6645H5.21875L8.79475 12.2405L7.85208 13.1832L2.66675 7.99783L7.85208 2.8125L8.79475 3.75517L5.21875 7.33117Z"
                        fill="#2D3748"
                      />
                    </svg>
                  </button>
                  {content?.primaryKeyword && <TitleGuideline guideline={content} />}
                </div>
              </div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeNav === '1' ? 'actived' : ''}
                    onClick={() => setActiveNav('1')}
                  >
                    {translate('Summary')}
                  </NavLink>
                </NavItem>
                {!content?.googleDocId && (
                  <NavItem>
                    <NavLink
                      className={activeNav === '2' ? 'actived' : ''}
                      onClick={() => setActiveNav('2')}
                    >
                      {translate('Content')}
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    className={activeNav === '3' ? 'actived' : ''}
                    onClick={() => setActiveNav('3')}
                  >
                    {translate('Performance')}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeNav}>
                {isLoading ? (
                  <>
                    <Shimmer height="22em" style={{ marginBottom: '3em' }} />
                    <Shimmer height="10em" style={{ marginBottom: '3em' }} />
                    <Shimmer height="15em" />
                  </>
                ) : (
                  <TabPane tabId="1">
                    <TableResumeReview
                      data={content}
                      accountId={currentAccountId}
                      guidelineId={id}
                    />
                  </TabPane>
                )}
              </TabContent>
              <TabContent activeTab={activeNav}>
                <TabPane tabId="2">
                  <ContainerEditContent
                    content={content}
                    setIsPageLoading={setIsPageLoading}
                    noIA
                  />
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeNav}>
                <TabPane tabId="3">
                  <PerformanceData
                    primaryKeyword={content?.primaryKeyword}
                    secondaryKeywords={content?.secondaryKeywords}
                  />
                </TabPane>
              </TabContent>
            </div>
            <div className="container-side-column">
              <SendCardLayout>
                <p className="subtitle">{translate('Action')}</p>
                <Button
                  className="approve"
                  onClick={toggleModalApprove}
                  disabled={!isTasksCompleted}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      d="M9.5 16.5C5.35775 16.5 2 13.1423 2 9C2 4.85775 5.35775 1.5 9.5 1.5C13.6423 1.5 17 4.85775 17 9C17 13.1423 13.6423 16.5 9.5 16.5ZM8.75225 12L14.0548 6.69675L12.9943 5.63625L8.75225 9.879L6.6305 7.75725L5.57 8.81775L8.75225 12Z"
                      fill="white"
                    />
                  </svg>
                  {translate('Approve content')}
                </Button>
                <ModalApprove
                  modalFunction={toggleModalApprove}
                  modalState={modalApprove}
                  dataIndividual={[
                    { accountId: currentAccountId, column: 'CONTENT_APPROVAL', guidelineId: id },
                  ]}
                  redirectToGuideline
                />
              </SendCardLayout>
              <ApprovalChat setComplete={setIsTasksCompleted} />
            </div>
          </div>
        )}
      </div>
    </GuidelineLayout>
  );
};

export default ContentApproval;
